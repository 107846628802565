<template>
  <v-card class="card-crd card-shadow border" color="white">
    <v-card class="d-flex justify-end" style="display: flex; justify-content: flex-end;" color="lighten-4" flat tile>
      <v-btn small class="ma-2" outlined color="black" :loading="downloadBtnLoading" :disabled="downloadBtnLoading" @click.prevent="downloadCsv()">
        <v-icon>mdi-download-outline</v-icon>Download Csv
      </v-btn>
    </v-card>
    <v-row align="end" class="pa-8" style="gap: 30px">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />
      <v-btn color="primary" @click.prevent="currentPage = 1;Business(search)">
        Search
      </v-btn>
    </v-row>
    <v-tabs v-model="selectedTab" @change="handleTabChange">
      <v-tab v-for="(tab, index) in tabs" :key="index">
        {{ tab.label }}
      </v-tab>
    </v-tabs>


    <v-data-table :headers="headers" :items="cardData" hide-default-footer :loading="loading"
                  :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.lastFour="{ item }">
        <v-title text>
          ****{{ item.lastFour }}
        </v-title>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn text
               :class="{ 'success-sec-btn': item.status === 'ACTIVE', 'error-sec-btn': item.status === 'BLOCKED', 'warning-sec-btn': item.status === 'PENDING' }"
               class="default-btn">
          {{ item.status }}
        </v-btn>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn color="primary" small outlined @click.prevent="handleTransaction(item.id)">
          {{ item.action }}
        </v-btn>
      </template>
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages"
                      :total-visible="7" />
      </v-col>
    </v-row>
  </v-card>
</template>
  
<script>
export default {
  data() {
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,

      cardData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      downloadBtnLoading: false,
      tabs: [
        {
          label: 'All',
          businessData: [],
        },
        {
          label: 'Active',
          businessData: [],
        },
        {
          label: 'SUSPENDED',
          businessData: [],
        },
  
      ],
      headers: [
        { text: 'Card No', value: 'lastFour' },
        { text: 'Status', value: 'status' },
        { text: 'Card Holder Name', value: 'card_holder_name' },
        { text: 'Card Type', value: 'cardType' },
        { text: 'Created Date', value: 'created_at' },
        { text: 'Action', value: 'action' },
      ],
      selectedTab: 0,
      search: '',

      abortController: new AbortController()
    };
  },
  props: {
    currentServer: Object,
  },
  methods: {
    handleTransaction(id) {
      /* const serverType = localStorage.getItem('serverType')
      if (serverType == 'staging') {
        this.$router.push('/skyfi-dashboard/staging/transactions/' + id + '/?for=card');
      } else if (serverType == 'livestaging') {
        this.$router.push('/skyfi-dashboard/live-staging/transactions/' + id + '/?for=card');
      } else if (serverType == 'local') {
        this.$router.push('/skyfi-dashboard/local/transactions/' + id + '/?for=card');
      } else {
        this.$router.push('/skyfi-dashboard/transactions/' + id + '/?for=card');
      } */
      this.$router.push('/skyfi-dashboard/transactions/' + id + '/?for=card');
    },

    tabName(tab) {
      switch (tab) {
      case 0:
        return ''
      case 1:
        return '&status=ACTIVE'
      case 2:
        return '&status=SUSPENDED'
      default:
        break;
      }
    },
    async Business(searchString) {
      try {
        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const v_status = this.tabName(this.selectedTab)

        const accountId = this.$route.params.id ? '&account_id=' + this.$route.params.id : '';
        const SearchString = searchString ? '&search_string=' + searchString : '';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/cards${v_page + v_per_page + accountId + SearchString + v_status}`,
          {
            method: 'GET',
            headers: myHeaders,
            'Content-Type': 'application/json',
            redirect: 'follow',
            signal
          }
        );
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        const accountCard = data?.data;
        const cardArray = accountCard?.map((item) => {
          let status = item.attributes.status;
    if (status === 'BLOCKED' || status === 'PENDING') {
        status = 'SUSPENDED';
    }
          return {
            id: item.id,
            cardType: item.attributes.cardType,
            status: status,
            lastFour: item?.attributes?.lastFour,
            card_holder_name: item?.attributes?.name,
            created_at: this.formatDate(item?.attributes?.created_at),
            action: 'Transaction',
          }
        })
        this.cardData = cardArray
        if(this.selectedTab === 0) {
          this.cardData = cardArray;
        }
        else {
          this.cardData = cardArray.filter(card => card.status === this.tabs[this.selectedTab].label.toUpperCase());
        }
        this.noOfPages = Math.ceil(parseInt(data?.total_count) / this.currentRows)
        this.loading = false
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.name === 'AbortError') {
          // Request was cancelled
          this.loading = true
          this.message = 'Loading... Please wait'
        } else if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
      }
    },
    async downloadCsv() {
      this.downloadBtnLoading = true;
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = process.env.VUE_APP_API_BASE_URL
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const search = this.search ? '&search_string=' + this.search : '';
      const status = this.tabName(this.selectedTab)
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      try {
        const url = this.dates?.length ? `&${this.getDateQuery(this.dates)}`:''
        const response = await fetch(baseurl +`admin/cards/export_card_data_to_csv?export_csv=true${url}` + search + status, requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.text();
        const blob = new Blob([data], { type: 'text/csv' });

        // Handle successful login here
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // link.href = data?.transaction_url;
        link.download = 'data.csv'; // Specify the filename for the downloaded file
        link.target = '_blank'
        // Simulate a click on the anchor element to trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up the anchor element
        document.body.removeChild(link);
        this.downloadBtnLoading = false;
      } catch (error) {
        this.downloadBtnLoading = false;
        this.dialogForDownload = false;
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.errorBar = true
          this.errorMessage = error.message
        }
      }
    },
    formatDate(inputDate) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];

      const date = new Date(inputDate);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedDate = `${month} ${day}, ${year}, ${hours % 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;

      return formattedDate;
    },
    pageChange() {
      this.Business(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.Business(this.search);
    },
    handleTabChange() {
      this.currentPage = 1
      this.Business(this.search);
    }
  },
  mounted() {
    this.Business();
  },
  watch: {
    $route() {
      this.message = 'Loading... Please wait'
      this.loading = true
      this.search = ''
      this.Business()
    },
  },
};
</script>

<style>
.v-data-table-header th:last-child i{display: none;}
/* .card-crd .v-slide-group__content .v-tab:nth-child(3){display: none;} */
/* .card-crd .v-slide-group__content .v-tab:nth-child(4){display: none;}
.card-crd .v-slide-group__content .v-tab:nth-child(5){display: none;}
.card-crd .v-slide-group__content .v-tab:nth-child(6){display: none;} */
</style>